<template>
	<v-form ref="form" style="width: 100%" @input="setIsValid($event)">
		<w-text-input
			v-model="product.name"
			autofocus
			:label="$t('sa.products.name')"
			maxlength="191"
			required
			:error-messages="localErrorMessages.name"
			:rules="[rules.noSpecialChar]"
		/>

		<v-layout column>
			<v-flex shrink>
				<v-subheader>{{ $t('sa.accounting-firms.modules_for_accounting_firms') }}</v-subheader>
				<v-layout row wrap>
					<v-flex v-for="item in modulesForAccountingFirms" :key="item.id" xs4>
						<v-switch v-model="item.subscribe" color="primary" :label="setTitle(item)" @change="selectModule(item)" />
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex shrink>
				<v-subheader>{{ $t('sa.accounting-firms.modules_for_vendors') }}</v-subheader>
				<v-layout row wrap>
					<v-flex v-for="item in modulesForVendors" :key="item.id" xs4>
						<v-switch v-model="item.subscribe" color="primary" :label="setTitle(item)" @change="selectModule(item)" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-form>
</template>

<script>
import Vue from 'vue'
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import Validator from '@/mixins/Validator'
export default {
	name: 'ProductForm',
	mixins: [SuperAdminModuleGuard, Validator],
	props: {
		value: {
			default: () => ({
				name : null,
				modules: []
			}),
			required: false,
			type: Object
		},
		isValid: {
			default: false,
			required: false,
			type: Boolean
		},
		errorMessages: {
			default: () => ({
				name: null,
			}),
			required: false,
			type: Object
		},
	},
	data: function() {
		return {
			localErrorMessages: this.errorMessages,
			modulesForAccountingFirms: [],
			modulesForVendors: [],
		}
	},
	computed: {
		product: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
	},
	watch: {
		product: {
			handler: function () {
				this.resetValidation()
				this.setModules()
			},
			deep: true
		}
	},
	mounted: function () {
		this.resetValidation()
		this.loadModules()
	},
	methods: {
		setIsValid: function (isValid) {
			this.$emit('update:is-valid', isValid)
		},
		resetValidation: function () {
			this.localErrorMessages.name = null;
			this.$refs.form.resetValidation()
		},
		loadModules: async function () {
			try {
				const { modules_for_accounting_firms: modulesForAccountingFirms, modules_for_companies: modulesForVendors } = await this.service.getAccountingFirmModules()
				this.modulesForAccountingFirms = modulesForAccountingFirms
				this.modulesForVendors = modulesForVendors
				this.setModules()
			} catch (error) {
				// eslint-disable-next-line no-empty
			}
		},
		selectModule: function(item) {
			if (item.subscribe) {
				return this.product.modules.push(item)
			}
			this.product.modules = this.product.modules.filter(elt => elt.id !== item.id)
		},
		setModules : function() {
			const moduleIds = new Set(this.product.modules.map(m => m.id));
			const setSubscribe = items => {
				items.forEach(item => {
					Vue.set(item, 'subscribe', moduleIds.has(item.id));
				});
			};
			setSubscribe(this.modulesForAccountingFirms);
			setSubscribe(this.modulesForVendors);
		},
		setTitle : function(item) {
			return item.title + ' (' + item.name + ')'
		}
	}
}
</script>
